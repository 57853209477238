<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on }">
      <button v-on="on" :class="btnClass" v-html="btnContent"></button>
    </template>

    <b-form ref="form" @submit="onSubmit" @reset="onReset" role="group">
      <v-card>
        <v-card-title>
          <span class="headline">{{ title }}</span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col xs="12" md="12">
              <b-form-group
                label-for="input-feed"
                :invalid-feedback="invalidFeedback"
                :state="state"
              >
                <b-form-input
                  id="input-feed"
                  placeholder="Feed"
                  v-model="form.feed"
                  :state="state"
                  trim
                ></b-form-input>
              </b-form-group>
            </v-col>

            <v-col xs="12" md="12" v-if="!isSimpleView">
              <b-form-group
                description="Select User"
                label-for="select-user"
                :state="state"
              >
                <b-form-select
                  id="select-user"
                  v-model="form.user"
                  :options="users"
                ></b-form-select>
              </b-form-group>
            </v-col>

            <v-col xs="12" md="12" v-if="0 < errors.length">
              <ul id="errors-list" class="text-danger">
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn type="reset" variant="danger" class="mx-1">Cancel</v-btn>
          <v-btn
            type="submit"
            class="mx-1 btn"
            style="
               {
                border-color: #1c195c;
                color: #ffffff;
                background-color: #1c195c;
              }
            "
            :loading="loading"
            :disabled="loading"
            >Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </b-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { getUsers, setFeedToUser } from "@/api/v1/getters";

const REGEX_URL_START = /^https?:\/\//;

export default {
  name: "soon-available",
  computed: {
    ...mapGetters(["isAdmin", "isPaidCustomer"]),
    state() {
      return this.form.feed.length >= 10 && REGEX_URL_START.test(this.form.feed)
        ? true
        : false;
    },
    invalidFeedback() {
      if (this.form.feed.length >= 10 && REGEX_URL_START.test(this.form.feed)) {
        return "";
      } else if (
        10 <= this.form.feed.length ||
        REGEX_URL_START.test(this.form.feed)
      ) {
        return "Enter an valid feed url";
      } else {
        return "Please enter a valid feed url";
      }
    }
  },
  data() {
    return {
      errors: [],
      timeout: 25000,
      dialog: false,
      loading: false,
      users: [],
      form: {
        feed: "",
        user: 0
      }
    };
  },
  components: {},
  props: {
    btnContent: String,
    btnClass: String,
    isSimpleView: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "Add New Podcast"
    }
  },
  mounted() {
    this.loadUser();
  },
  methods: {
    onSend() {
      this.$refs.form.submit();
    },
    onSubmit(evt) {
      evt.preventDefault();

      if (this.form.feed.length >= 10 && REGEX_URL_START.test(this.form.feed)) {
        this.pushFeed(this.form);
      }
    },
    onReset() {
      this.dialog = false;
    },
    loadUser() {
      this.users.loading = true;
      this.errors = [];

      getUsers({}, this.axios)
        .then(data => {
          if (data.hasOwnProperty("counter") && data.hasOwnProperty("rows")) {
            this.users = data.rows.map(row => {
              return {
                value: row.id,
                text: `${row.email} (${row.firstName} | ${row.lastName})`
              };
            });
          } else {
            this.items = [];
          }
        })
        .catch(() => {
          this.users.loading = false;
        });
    },

    pushFeed() {
      this.loading = true;
      this.errors = [];

      setFeedToUser(this.form, this.axios)
        .then(() => {
          this.loading = false;
          this.dialog = false;

          this.form.feed = "";
          this.form.user = 0;

          this.$emit("reloadListOfPodcast");
        })
        .catch(error => {
          this.loading = false;

          if (
            error.response &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("errors")
          ) {
            this.errors.push(error.response.data.errors);
          } else {
            this.errors.push("Unknown error");
          }
        });
    }
  }
};
</script>
