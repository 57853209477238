<template>
  <v-row>
    <v-col cols="12">
      <b-card
        border-variant="secondary"
        header-border-variant="secondary"
        class="h-100"
      >
        <template v-slot:header>
          <v-row>
            <v-col
              sm="2"
              class="align-middle"
              justify="center"
              v-bind:style="{ margin: '12px auto' }"
              >{{ totalRows }} Podcasts</v-col
            >
            <v-col
              :sm="
                true === showAddNew ? (true === showSelectedItem ? 6 : 8) : 10
              "
              class="align-middle d-inline mt-3"
              justify="center"
            >
              <v-row>
                <v-text-field
                  hide-details
                  label="Search"
                  prepend-inner-icon="search"
                  solo
                  filled
                  color="blue"
                  dense
                  clearable
                  @input="inputSearchHandler"
                ></v-text-field>

                <v-btn
                  text
                  icon
                  color="blue lighten-2"
                  style="margin: 0px 10px !important"
                  v-on:click="load()"
                >
                  <v-icon>mdi-autorenew</v-icon>
                </v-btn>
              </v-row>
            </v-col>
            <v-col
              sm="2"
              class="align-middle d-inline"
              justify="center"
              v-bind:style="{ margin: 'auto' }"
              v-if="true === showSelectedItem"
            >
              <v-checkbox
                v-model="showSelected"
                class="inter align-middle m-0 my-0"
                label="Show selected"
                hide-details
              >
              </v-checkbox>
            </v-col>
            <v-col
              sm="2"
              class="align-middle text-right"
              v-if="true === showAddNew"
            >
              <template>
                <AddPodcast
                  v-on:reloadListOfPodcast="load"
                  v-bind="{
                    btnClass: 'btn btn-primary float-right',
                    btnContent:
                      '<i class=&quot;flaticon2-plus pr-0 pr-md-2&quot;></i> <span class=&quot;d-none d-sm-inline align-sub&quot;> Add podcast</span>'
                  }"
                ></AddPodcast>
              </template>
            </v-col>
          </v-row>
        </template>

        <v-row
          style="min-height: 740px"
          :align="true === loading ? 'center' : 'start'"
          justify="center"
        >
          <v-col
            cols="12"
            v-if="true === loading"
            class="align-middle text-center"
          >
            <v-progress-circular
              indeterminate
              color="amber"
            ></v-progress-circular>
          </v-col>

          <b-table
            v-if="false === loading"
            show-empty
            stacked="md"
            :striped="striped"
            :bordered="bordered"
            :borderless="borderless"
            :outlined="outlined"
            :small="small"
            :hover="hover"
            :dark="dark"
            :fixed="fixed"
            :foot-clone="footClone"
            :no-border-collapse="noCollapse"
            :head-variant="headVariant"
            :table-variant="tableVariant"
            :items="filterItem(items)"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filterIncludedFields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
            @row-clicked="checkboxChecked"
          >
            <template v-slot:head(checkbox) v-if="true === showCheckbox">
              <b-form-checkbox
                v-model="checkboxAll"
                class="align-middle"
                v-on:change="checkboxCheckedAll()"
              ></b-form-checkbox>
            </template>

            <template v-slot:cell(checkbox)="row" v-if="true === showCheckbox">
              <v-row>
                <v-col>
                  <b-form-checkbox
                    v-model="row.item.selected"
                    class="align-middle"
                    v-on:change="checkboxChecked(row.item, row.index)"
                  ></b-form-checkbox>
                </v-col>
              </v-row>
            </template>

            <template v-slot:cell(name)="row">{{
              cutSentence(row.item.name, 40)
            }}</template>

            <template v-slot:cell(title)="row">{{
              cutSentence(row.item.title, 300)
            }}</template>

            <template v-slot:cell(attempt)="row">
              <v-row class="px-1">
                <v-col cols="12">
                  <v-tooltip bottom max-width="800">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        :color="
                          true === row.item.locked || 3 <= row.item.attempt
                            ? 'red'
                            : 0 < row.item.attempt
                            ? 'orange'
                            : 'green'
                        "
                        title="Reset attempt"
                        :loading="row.item.loading.resetAttempt"
                        v-on:click="resetAttempt(row.item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon v-if="true === row.item.locked ? true : false"
                          >mdi-lock</v-icon
                        >
                        <v-icon v-if="true === row.item.locked ? false : true"
                          >mdi-lock-open-outline</v-icon
                        >
                      </v-btn>
                    </template>
                    <span
                      >{{ row.item.attempt }} :
                      {{ row.item.message ? row.item.message : "-" }}</span
                    >
                  </v-tooltip>
                </v-col>
              </v-row>
            </template>

            <template v-slot:cell(actions)="row">
              <v-row class="px-1">
                <v-col cols="12" style="displ">
                  <v-btn
                    icon
                    color="blue"
                    :href="row.item.url"
                    title="Go to listen on Podinstall.com"
                    target="_blank"
                    v-if="true === showOnPodInstall"
                  >
                    <v-icon>mdi-link</v-icon>
                  </v-btn>

                  <v-btn
                    icon
                    color="blue"
                    :href="row.item.feedUrl"
                    title="See RSS feed"
                    target="_blank"
                    v-if="true === showFeed"
                  >
                    <v-icon>mdi-rss-box</v-icon>
                  </v-btn>

                  <router-link
                    :to="{
                      name: 'admin_list_podcasts_episodes',
                      params: { id: row.item.id }
                    }"
                    v-slot="{ href, route, navigate }"
                    v-if="true === showEpisodes"
                  >
                    <v-btn
                      icon
                      color="blue"
                      :href="href"
                      @click="navigate"
                      title="See Episodes"
                    >
                      <v-icon>mdi-animation-play</v-icon>
                    </v-btn>
                  </router-link>

                  <v-btn
                    icon
                    color="orange darken-2"
                    title="Delete feed"
                    :loading="row.item.loading.deleted"
                    v-on:click="deleteModal('open', row.item, row.index)"
                    v-if="true === showDelete"
                  >
                    <v-icon>mdi-delete-forever</v-icon>
                  </v-btn>

                  <v-btn
                    icon
                    @click.stop.prevent="copyToClipboard(row.item.id)"
                    v-if="true === showDelete"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>

                  <v-tooltip
                    v-for="(item, index) in linksFlux"
                    :key="`button_feed_${index}`"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        :href="item.getFeed(row.item)"
                        @click.stop.prevent="
                          copyToClipboard(item.getFeed(row.item))
                        "
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>{{ item.icon }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ item.tooltip }}</span>
                  </v-tooltip>

                  <v-btn
                    icon
                    :color="
                      row.item.isForecasted
                        ? 'orange'
                        : row.item.forceParsing
                        ? 'blue'
                        : 'green'
                    "
                    :title="
                      'Force parsing indefinitely' +
                        (row.item.isForecasted
                          ? ' (forecast)'
                          : row.item.forceParsing
                          ? ' (force parsing)'
                          : '')
                    "
                    :disabled="row.item.hasDomain"
                    @click.stop.prevent="forceParsing(row.item)"
                  >
                    <v-icon small>mdi-flash</v-icon>
                  </v-btn>

                  <v-btn
                    icon
                    color="orange"
                    title="Force parsing"
                    @click.stop.prevent="forceExecution(row.item)"
                  >
                    <v-icon small>mdi-alert-octagon</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </b-table>
        </v-row>

        <input
          type="hidden"
          id="copyClipboard"
          class="form-control"
          style="width: 100px"
        />

        <template v-slot:footer>
          <b-row>
            <b-pagination
              v-bind:style="{ margin: '0 auto' }"
              align="center"
              justify="center"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              size="sm"
              class="my-0"
            ></b-pagination>
          </b-row>
        </template>
      </b-card>
    </v-col>

    <v-col cols="12">
      <v-dialog v-model="dialog.delete.status" width="500" persistent>
        <v-card>
          <v-card-title class="headline">Delete Podcast</v-card-title>

          <v-card-text
            >This podcast will be deleted from cache. Are you sure
            ?</v-card-text
          >

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="darken-1" text v-on:click="deleteModal('close')"
              >NO</v-btn
            >

            <v-btn
              type="submit"
              class="mx-1 btn"
              style="
                 {
                  border-color: #1c195c;
                  color: #ffffff;
                  background-color: #1c195c;
                }
              "
              title="Delete feed"
              :loading="dialog.delete.loading"
              v-on:click="deletePodcast()"
              >YES</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import {
  getPodcasts,
  deletePodcast,
  resetattemptPodcast,
  forceparsingPodcast,
  forceParsingNow
} from "@/api/v1/getters";
import AddPodcast from "@/views/partials/widgets/AddPodcast";

export default {
  name: "ListingPodcast",

  components: {
    AddPodcast
  },

  props: {
    showDelete: {
      type: Boolean,
      default: false
    },
    showOnPodInstall: {
      type: Boolean,
      default: true
    },
    showFeed: {
      type: Boolean,
      default: false
    },
    showAddNew: {
      type: Boolean,
      default: false
    },
    showCheckbox: {
      type: Boolean,
      default: false
    },
    showEpisodes: {
      type: Boolean,
      default: false
    },
    showAttempt: {
      type: Boolean,
      default: false
    },
    showSelectedItem: {
      type: Boolean,
      default: false
    },
    itemsSelected: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  data() {
    return {
      timeout: 25000,
      dialog: {
        delete: {
          status: false,
          loading: false,
          item: null,
          index: -1
        }
      },
      loading: false,
      showSelected: false,
      searchFilter: "",
      items: [],
      linksFlux: [
        {
          label: "Flux Rss",
          value: "rss",
          icon: "mdi-rss",
          tooltip: "Copy feed RSS",
          getFeed: this.getRssFeed
        },
        {
          label: "Flux Atom",
          value: "atom",
          icon: "mdi-atom",
          tooltip: "Copy feed RSS-Atom",
          getFeed: this.getAtomFeed
        },
        {
          label: "Flux Podcast",
          value: "pdocast",
          icon: "mdi-podcast",
          tooltip: "Copy feed podcast",
          getFeed: this.getPodcastFeed
        }
      ],
      fields: [
        {
          key: "checkbox",
          sortable: false,
          sortDirection: "desc",
          class: "text-center align-middle p-0"
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
          sortDirection: "desc",
          class: "align-middle"
        },
        {
          key: "title",
          label: "Title",
          sortable: true,
          sortDirection: "desc",
          tdClass: "table-title-wm align-middle p-0"
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
          sortDirection: "desc",
          class: "text-center align-middle p-0"
        },
        {
          key: "attempt",
          label: "Attempt",
          sortable: true,
          sortDirection: "desc",
          class: "text-center align-middle p-0"
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      tableVariants: [
        "primary",
        "secondary",
        "info",
        "danger",
        "warning",
        "success",
        "light",
        "dark"
      ],
      striped: true,
      bordered: false,
      borderless: false,
      outlined: false,
      small: true,
      hover: true,
      dark: false,
      fixed: false,
      footClone: false,
      headVariant: null,
      tableVariant: "",
      noCollapse: false,
      checkboxAll: false,
      elements: []
    };
  },

  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },

  mounted() {
    this.load();

    if (false === this.showCheckbox) {
      let fields = this.fields;
      this.fields = fields.slice(1, fields.length);
    }

    if (false === this.showAttempt) {
      let fields = this.fields;
      fields.pop();
      this.fields = fields;
    }
  },

  methods: {
    load() {
      this.searchFilter = "";
      this.loading = true;
      getPodcasts({}, this.axios)
        .then(data => {
          let elements = [];
          if (data.hasOwnProperty("counter") && data.hasOwnProperty("rows")) {
            this.items = data.rows.map(row => {
              let selected = false;

              if (-1 !== this.itemsSelected.indexOf(row.id)) {
                elements.push(row.id);
                selected = true;
              }

              return {
                ...row,
                selected: selected,
                loading: {
                  deleted: false,
                  resetAttempt: false
                }
              };
            });
          } else if (Array.isArray(data)) {
            this.totalRows = data.length;
            this.items = data.map(row => {
              let selected = false;

              if (-1 !== this.itemsSelected.indexOf(row.id)) {
                elements.push(row.id);
                selected = true;
              }

              return {
                ...row,
                selected: selected,
                loading: {
                  deleted: false,
                  resetAttempt: false
                }
              };
            });
          } else {
            this.totalRows = 1;
            this.items = [];
          }
          this.elements = elements;
          this.checkboxAll =
            this.elements.length == this.items.length ? true : false;
        })
        .catch(() => {
          // TODO: Print error
        })
        .finally(() => {
          this.loading = false;
        });
    },

    deleteModal(type, item, index) {
      if ("open" == type) {
        this.dialog.delete = {
          status: true,
          item: item,
          index: index
        };
      } else {
        this.dialog.delete = {
          status: false,
          loading: false,
          item: null,
          index: -1
        };
      }
    },

    deletePodcast() {
      const item = this.dialog.delete.item;
      if (item) {
        this.dialog.delete.loading = true;

        deletePodcast(item.id, this.axios).finally(() => {
          this.load();
          this.deleteModal("close");
        });
      }
    },

    resetAttempt(item) {
      this.items = this.items.map(row => {
        const loading = row.loading;

        if (item.id === row.id) {
          return {
            ...row,
            loading: { ...loading, resetAttempt: true }
          };
        }

        return row;
      });

      resetattemptPodcast(item.id, { locked: false }, this.axios)
        .then(data => {
          this.items = this.items.map(row => {
            const loading = row.loading;

            if (item.id === row.id) {
              return {
                ...data,
                loading: { ...loading, resetAttempt: false }
              };
            }

            return row;
          });
        })
        .catch(() => {
          // TODO: Print error
        })
        .finally(() => {
          // TODO:
        });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    inputSearchHandler(text) {
      if (text && text.length > 2) {
        this.searchFilter = text.toLowerCase();
      } else {
        this.searchFilter = "";
      }
    },

    filterItem(items) {
      let rows = items;

      if (this.searchFilter.length > 0) {
        rows = items.filter(
          item =>
            (item.id || "").includes(this.searchFilter) ||
            (item.name || "").toLowerCase().includes(this.searchFilter) ||
            (item.title || "").toLowerCase().includes(this.searchFilter) ||
            (item.domain || "").toLowerCase().includes(this.searchFilter)
        );
      }

      if (this.showSelected) {
        rows = rows.filter(item => {
          if (item.selected) {
            return true;
          }
          return false;
        });
      }

      this.totalRows = rows.length;

      return rows;
    },

    cutSentence(sentence, len) {
      if (!sentence) {
        return "";
      }

      let cutS = sentence.substring(0, len);
      if (40 < sentence.length) {
        cutS += "...";
      }
      return cutS;
    },

    checkboxCheckedAll() {
      let items = this.items;
      let filterItems = this.filterItem(this.items);
      let status = this.checkboxAll ? false : true;
      let elements = this.elements;

      const rows = items.map(row => {
        let curStatus = status;
        const found = filterItems.find(element => element.id === row.id);
        if (found) {
          const idx = elements.indexOf(found.id);
          if (-1 === idx && status) {
            // not exist, set to true
            elements.push(row.id);
            curStatus = true;
          } else if (status) {
            // already exist, nothing to do
            curStatus = true;
          } else if (!status) {
            // already exist, set to false
            elements.splice(idx, 1);
            curStatus = false;
          }
          return {
            ...row,
            selected: curStatus
          };
        }
        // nothing to change if not in filtered Items
        return row;
      });

      this.checkboxAll = elements.length == items.length ? true : false;
      this.elements = elements;
      this.items = rows;
    },

    checkboxChecked(item) {
      let items = this.items;
      let elements = this.elements;
      let status = false;

      const idx = elements.indexOf(item.id);
      if (-1 === idx) {
        elements.push(item.id);
        status = true;
      } else {
        elements.splice(idx, 1);
      }

      const rows = items.map(row => {
        if (item.id === row.id) {
          return {
            ...row,
            selected: status
          };
        }
        return row;
      });

      this.checkboxAll = elements.length == items.length ? true : false;
      this.elements = elements;
      this.items = rows;
    },

    forceParsing(item) {
      forceparsingPodcast(item.id, { forceparsing: false }, this.axios)
        .then(data => {
          this.items = this.items.map(row => {
            if (item.id === row.id) {
              return {
                ...row,
                ...data
              };
            }

            return row;
          });
        })
        .catch(() => {
          // TODO: Print error
        })
        .finally(() => {
          // TODO:
        });
    },

    forceExecution(item) {
      const title = `Force import program`;

      forceParsingNow({ feeds: [item.id] }, this.axios)
        .then(data => {
          this.$bvToast.toast(data.messages.join("\n"), {
            title: title,
            solid: true,
            variant: "success",
            appendToast: false,
            autoHideDelay: 3000
          });
        })
        .catch(e => {
          this.$bvToast.toast(`Error`, {
            title: title,
            solid: true,
            variant: "danger",
            appendToast: false
          });

          console.error(e);
        });
    },

    copyToClipboard(value) {
      let copyContent = document.querySelector(`#copyClipboard`);
      copyContent.value = value;
      copyContent.setAttribute("type", "text");
      copyContent.focus();

      if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        copyContent.contentEditable = true;
        copyContent.readOnly = true;

        const range = document.createRange();
        range.selectNodeContents(copyContent);

        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);

        copyContent.setSelectionRange(0, 999999);
      } else {
        copyContent.select();
      }

      let message = "success";
      let variant = "success";
      let autoHideDelay = 3000;

      try {
        document.execCommand("Copy");
      } catch (err) {
        message = "unknown error";
        variant = "danger";
      }

      this.$bvToast.toast(`Copy to clipboard`, {
        title: `Copy to clipboard ${message}`,
        toaster: "b-toaster-bottom-right",
        solid: true,
        variant: variant,
        appendToast: false,
        autoHideDelay: autoHideDelay
      });

      copyContent.setAttribute("type", "hidden");
    },

    getRssFeed(podcast) {
      const baseUrl = podcast.url;
      return `${baseUrl}/feed/rss/website.xml`;
    },

    getAtomFeed(podcast) {
      const baseUrl = podcast.url;
      return `${baseUrl}/feed/atom/website.xml`;
    },

    getPodcastFeed(podcast) {
      const baseUrl = podcast.url;
      return `${baseUrl}/feed/podcast/website.xml`;
    }
  }
};
</script>
