<template>
  <b-container class="kt-container" fluid>
    <b-row>
      <b-col xs="12" v-if="!isAdmin()">
        <h2 class="text-left pl-5">Forbidden</h2>
      </b-col>

      <b-col xs="12" v-if="isAdmin()">
        <ListingPodcast
          v-bind:showDelete="true"
          v-bind:showOnPodInstall="true"
          v-bind:showFeed="true"
          v-bind:showAddNew="true"
          v-bind:showEpisodes="true"
          v-bind:showAttempt="true"
        ></ListingPodcast>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import ListingPodcast from "@/components/ListingPodcast";
import { mapGetters } from "vuex";

export default {
  name: "PodcastList",

  components: {
    ListingPodcast
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Podcasts" }]);
  },

  methods: {
    ...mapGetters(["isAdmin"])
  }
};
</script>
